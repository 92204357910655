<template>
  <div>
    <component :is="appropriateComponent" v-if="appropriateComponent" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Campaign",

  metaInfo: {
    title: "Campaign",
  },

  components: {
    OwnedCampaign: () => import("@/views/campaigns/OwnedCampaign"),
    NonOwnedCampaign: () => import("@/views/campaigns/NonOwnedCampaign"),
  },

  computed: {
    ...mapGetters(["getUserInfo", "getOrganization", "getCampaigns"]),

    appropriateComponent() {
      if (this.getCampaigns != null) {
        const campaign = this.getCampaigns.find((c) => c._id === this.$route.params.id);

        if (this.getOrganization?._id === campaign?.orgId) {
          return "OwnedCampaign";
        }
      }

      return "NonOwnedCampaign";
    },
  },
};
</script>
